// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { Newsletter } from '@components/Teasers/Newsletter';
import { gql } from '@generated/gql';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import type { TeaserFragment } from './Teaser';
import { Teaser } from './Teaser';
import styles from './Teasers.module.sass';
import type { IProps } from './Teasers.d';
// #endregion Local Imports
export const TeasersFragment = gql(/* GraphQL */ `
  fragment TeasersFragment on EntityQueryResult {
    entities {
      value: entityTranslation(language: $language) {
        ... on BlockContentTeasers {
          id: entityUuid
          title: fieldTitle
          byline: fieldByline
          contents: fieldContent {
            targetId
            targetRevisionId
            value: entity {
              translation: entityTranslation(language: $language) {
                ...TeaserFragment
              }
            }
          }
        }
      }
    }
  }
`);

export const Teasers: React.FunctionComponent<IProps.Teasers> = ({
  teasers: _teasers,
  language,
}) => {
  const teasers = getFragmentData(TeasersFragment, _teasers);

  return (
    <div className={styles.Teasers__root}>
      {teasers.entities?.map((_teaser, indexTeasers) => {
        const teaser = _teaser?.value;
        if (teaser?.__typename === 'BlockContentTeasers') {
          return (
            <div
              className={cx(
                styles.Teasers__item,
                styles[`Teasers__item__index-${String(indexTeasers)}`],
              )}
              key={teaser.id}
            >
              <div className={styles.Teasers__header}>
                <h4 className={cx(styles.Teasers__title, 'h1-small')}>
                  {teaser.byline}
                </h4>
                <h1 className={styles.Teasers__byline}>{teaser.title}</h1>
              </div>
              {Boolean(teaser.contents) &&
                teaser.contents?.map((content, indexContents: number) => {
                  return (
                    <Teaser
                      after={
                        <Newsletter
                          indexContents={indexContents}
                          indexTeasers={indexTeasers}
                          language={language}
                        />
                      }
                      key={content?.targetId}
                      teaser={
                        content?.value?.translation as FragmentType<
                          typeof TeaserFragment
                        >
                      }
                    />
                  );
                })}
            </div>
          );
        }

        return <></>;
      })}
    </div>
  );
};
