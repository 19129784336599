// #region Global Imports
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
// import { Adition } from '@components/Adition/Adition';
import type { IProps } from '@components/PostTeasers/PostTeasers.d';
import styles from './PostTeaserLink.module.sass';
// import { getFragmentData } from '@generated/fragment-masking';
// import { AdFragment } from '@components/Adition';
// #endregion Local Imports

export const PostTeaserLink: React.FC<IProps.PostTeaserLink> = ({
  title,
  content,
}) => {
  // if (content.adId) {
  //   const ad = getFragmentData(AdFragment, content);
  //   const { slot, language, adId}
  //   return (
  //     <div className={styles.PostTeaserLink__root}>
  //       <h4 className="h1-small h1-underline">{title}</h4>
  //       <div className={styles.PostTeaserLink__content}>
  //         <div>
  //           <Adition
  //             adId={ad.adId} // [de, fr]
  //             languageId={ad.language}
  //             slot={ad.slot}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className={styles.PostTeaserLink__root}>
      {title}
      {Boolean(content) && (
        <div className={styles.PostTeaserLink__content}>{content}</div>
      )}
    </div>
  );
};
