'use client';

import React from 'react';
import styles from './styles.module.sass';
import { IProps, IState } from '@components/Evalanche/Evalanche';

// https://scnem.com/art_resource.php?sid=d894g.erhq44&form_EMAIL=andreas.eberhard%40galledia.ch
// https://scnem.com/html/default_confirmation_de.html?form_EMAIL=andreas.eberhard@galledia.ch

// TODO: Move state into constants
export const Evalanche: React.FC<IProps.Evalanche> = ({ language }) => {
  const { locale } = language;
  const [state, setState] = React.useState<IState.Evalanche>({
    response: false,
    sid: {
      de: 'd894g.erhq44',
      fr: 'd89lp.1pqicfd',
    },
    uri: 'https://scnem.com/art_resource.php',
    value: '',
  });

  const { response } = state;
  let btntxt = 'Abonnieren';
  let success = 'Vielen Dank für Ihre Anmeldung.';

  if (locale !== 'de') {
    btntxt = 'Abonner';
    success = 'Merci pour votre inscription.';
  }

  const getSid = (lang: string | undefined): string => {
    if (lang) {
      // @ts-ignore
      return state.sid[lang] || state.sid.de;
    }
    return '';
  };

  const fixEvalancheNoCORS = (): void => {
    setState({
      ...state,
      response: true,
    });
  };

  const handleFormChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setState({
      ...state,
      value: e.currentTarget.value,
    });
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const { uri, value } = state;
    const request = `${uri}?sid=${getSid(locale)}&form_EMAIL=${value}`;
    fetch(request, {
      method: 'POST',
    })
      .then((response) => {
        fixEvalancheNoCORS();
      })
      .catch((error) => {
        fixEvalancheNoCORS();
      });
  };

  return (
    <div className={styles.Evalanche__root}>
      {response ? (
        <p>{success}</p>
      ) : (
        <form className={styles.Evalanche__form} onSubmit={handleFormSubmit}>
          <input
            id="evalanche"
            name="Newsletter"
            onChange={(e) => handleFormChange(e)}
            type="text"
            value={state.value}
          />
          <button type="submit" value="submit" className={'Button__btn'}>
            {btntxt}
          </button>
        </form>
      )}
    </div>
  );
};

export default Evalanche;
