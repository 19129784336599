// #region Global Imports
import * as React from 'react';
import Link from 'next/link';
// #endregion Global Imports
// #region Local Imports
import cx from 'classnames';
import type { IProps } from '@components/Button/Button';
import styles from './Button.module.sass';
// #endregion Local Imports

export const Button: React.FC<IProps.Button> = ({ label, path }) => {
  return (
    <div className={cx(styles.Button__root, 'Button__root')}>
      <Link
        className={cx(styles.Button__btn, 'Button__btn')}
        href={path || ' '}
      >
        {label || ' '}
      </Link>
    </div>
  );
};
