// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { Button } from '@components/Button';
import { gql } from '@generated/gql';
import { getFragmentData } from '@generated/fragment-masking';
import styles from './Teaser.module.sass';
import type { IProps } from './Teasers.d';
// #endregion Local Imports

export const TeaserFragment = gql(/* GraphQL */ `
  fragment TeaserFragment on ParagraphTeaser {
    id: entityId
    label: fieldByline
    title: fieldTitle
    text: fieldText {
      processed
    }
    button: fieldLink {
      url {
        path
      }
      title
    }
  }
`);

export const Teaser: React.FunctionComponent<IProps.Teaser> = ({
  teaser: _teaser,
  after,
}) => {
  const teaser = getFragmentData(TeaserFragment, _teaser);

  const text = teaser.text;
  const label = teaser.label;
  const title = teaser.title;
  const button = teaser.button;

  return (
    <>
      <div className={cx(styles.Teaser__root, 'Teaser__root')}>
        <h4 className={cx(styles.Teaser__label, 'h1-small')}>{label}</h4>
        <h1 className={styles.Teaser__title}>{title}</h1>
        <div
          className={styles.Teaser__text}
          dangerouslySetInnerHTML={{ __html: String(text?.processed) }}
        />
        <div className={styles.Teasers__btn}>
          <Button
            label={String(button?.title)}
            path={String(button?.url?.path)}
          />
        </div>
      </div>
      {after}
    </>
  );
};
