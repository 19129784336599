// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { gql } from '@generated/gql';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import { PostTeaser, PostTeaserLink } from '@components/PostTeasers';
import type { PostTeaserFragment } from '@components/PostTeasers';
import styles from './PostTeasers.module.sass';
import type { IProps } from './PostTeasers.d';
// #endregion Local Imports

export const PostTeasersFragment = gql(/* GraphQL */ `
  fragment PostTeasersFragment on EntityQueryResult {
    entities {
      value: entityTranslation(language: $language) {
        ...PostTeaserFragment
      }
    }
  }
`);

export const PostTeasers: React.FC<IProps.PostTeasers> = ({
  entityQueryResult: _entityQueryResult,
  termIssue,
  title = '',
  itemTitle = '',
  after,
  // ad: _ad,
}) => {
  const entityQueryResult = getFragmentData(
    PostTeasersFragment,
    _entityQueryResult,
  );

  return (
    <div className={cx(styles.PostTeasers__root, 'PostTeasers__root')}>
      {title}
      <div className={cx(styles.PostTeasers__items, 'PostTeasers__items')}>
        {entityQueryResult.entities?.map((teaser, index: number) => {
          // if (_ad && index === 1) {
          //   const ad = getFragmentData(AdFragment, _ad);
          //   return (
          //     <div
          //       className={cx(styles.PostTeasers__item, 'PostTeasers__item')}
          //       key={index
          //     >
          //       {/* <PostTeaserLink content={ad} title={ad?.label ?? ''} /> */}
          //     </div>
          //   );
          // }

          return (
            <div
              className={cx(
                styles.PostTeasers__item,
                'PostTeasers__item',
                `PostTeasers__item_${index}`,
              )}
              key={index}
            >
              <PostTeaserLink
                content={
                  <PostTeaser
                    teaser={
                      teaser?.value as FragmentType<typeof PostTeaserFragment>
                    }
                    termIssue={termIssue}
                  />
                }
                title={itemTitle}
              />
            </div>
          );
        })}
        {after}
      </div>
    </div>
  );
};
