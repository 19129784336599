// #region Global Imports
import React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';
import styles from '@components/Home/styles.module.sass';
import { Layout } from '@components/Layout';
import { Adition } from '@components/Adition/Adition';
import { Ads } from '@components/Adition/Ads';
import type { PostTeasersFragment } from '@components/PostTeasers';
import { PostTeasers } from '@components/PostTeasers';
import { Teasers } from '@components/Teasers';
import type { IssueFragment } from '@components/Issue';
import { Issue } from '@components/Issue';
import {
  InnerAds,
  InnerFeaturedShortmessagesHighlights,
  WrapperFeaturedShortmessagesHighlightsAds,
} from '@components/Wrapper/Home/Featured_ShortMessages_Highlights_Ads';
import {
  InnerFeaturedShortmessages,
  InnerHighlights,
  WrapperFeaturedShortmessagesHighlights,
} from '@components/Wrapper/Home/Featured_ShortMessages_Highlights';
import {
  InnerFeatured,
  InnerShortmessages,
  WrapperFeaturedShortmessages,
} from '@components/Wrapper/Home/Featured_ShortMessages';
import { WrapperOtherPosts } from '@components/Wrapper/Home/OtherPosts';
import {
  addApolloState,
  initializeApollo,
  withDefaultVariables,
} from '@services/apollo';
import { HomeQueryDocument } from '@generated/graphql';
import type { IPages } from 'types';
import { renderBlockContentTranslation } from '@lib/func/renderBlockContentTranslation';
import type { FragmentType } from '@generated/fragment-masking';
import { withSession } from '@lib/auth';
import { MostRead } from '@components/PostTeasers/MostRead';
import { Spinner } from '@components/Spinner';
// #endregion Local Imports

// this are the id's to get the filters right
// {field: "field_category.target_id", value: "..."}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const Home = ({
  variables,
  user,
  language,
}: IPages.Home): React.ReactElement => {
  const router = useRouter();
  const cacheReset = router.query.cache_reset;

  const { data, loading, client } = useQuery(HomeQueryDocument, {
    variables,
  });

  // eslint-disable-next-line import/no-named-as-default-member
  React.useEffect(() => {
    if (!isEmpty(cacheReset)) {
      void (async (): Promise<void> => {
        // This seems to be related with how we handle apollo cache.
        // Although we do clear the store server side, we need to
        // refresh the page client side.
        await client.clearStore();
        await router.replace(`/`, {}, { shallow: true })

        window.location.reload();
      })()
    }
  }, [cacheReset, router, client, language])

  if (loading || cacheReset) return <Spinner />;

  const {
    shortMessages,
    featured,
    currentIssue,
    teasers,
    otherPosts,
    highlights,
    termFeatured,
    termShortMessages,
    termHighlights,
    termOtherPosts,
    termSitetitle,
    termSiteclaim,
    termButtonAllIssues,
    termCurrentIssue,
    termIssue,
    termMostRead,
  } = data!;

  return (
    <div className="Home">
      <Layout
        data={data}
        header={
          <>
            {Boolean(termSitetitle) && (
              <h4 className={cx(styles.Home__claim, 'h1-small')}>
                {renderBlockContentTranslation(termSitetitle)}
              </h4>
            )}
            {Boolean(termSiteclaim) && (
              <h1>{renderBlockContentTranslation(termSiteclaim)}</h1>
            )}
          </>
        }
        language={language}
        user={user ?? {}}
      >
        <WrapperFeaturedShortmessagesHighlightsAds>
          <InnerFeaturedShortmessagesHighlights>
            <WrapperFeaturedShortmessagesHighlights>
              <InnerFeaturedShortmessages>
                <WrapperFeaturedShortmessages>
                  <InnerFeatured>
                    {featured ? (
                      <PostTeasers
                        entityQueryResult={
                          featured as FragmentType<typeof PostTeasersFragment>
                        }
                        termIssue={renderBlockContentTranslation(termIssue)}
                        title={
                          <h4 className="h1-small h1-underline">
                            {termFeatured?.entityLabel}
                          </h4>
                        }
                      />
                    ) : null}
                  </InnerFeatured>
                  <InnerShortmessages>
                    {shortMessages ? (
                      <PostTeasers
                        entityQueryResult={
                          shortMessages as FragmentType<
                            typeof PostTeasersFragment
                          >
                        }
                        termIssue={renderBlockContentTranslation(termIssue)}
                        title={
                          <h4 className="h1-small h1-underline">
                            {termShortMessages?.entityLabel}
                          </h4>
                        }
                      />
                    ) : null}
                  </InnerShortmessages>
                </WrapperFeaturedShortmessages>
              </InnerFeaturedShortmessages>
              <InnerHighlights>
                {highlights ? (
                  <PostTeasers
                    entityQueryResult={
                      highlights as FragmentType<typeof PostTeasersFragment>
                    }
                    itemTitle={
                      <h4 className="h1-small h1-underline">
                        {termHighlights?.entityLabel}
                      </h4>
                    }
                    termIssue={renderBlockContentTranslation(termIssue)}
                  />
                ) : null}
              </InnerHighlights>
            </WrapperFeaturedShortmessagesHighlights>
          </InnerFeaturedShortmessagesHighlights>
          <InnerAds>
            <Ads />
          </InnerAds>
        </WrapperFeaturedShortmessagesHighlightsAds>
        <Adition
          active
          adId={[4473030, 4504600]} // [de, fr]
          slot="SPECIAL"
          style="special"
        />
        <WrapperOtherPosts>
          {otherPosts ? (
            <>
              <PostTeasers
                after={
                  <MostRead
                    locale={language.locale ?? ''}
                    style="desktop"
                    termMostRead={renderBlockContentTranslation(
                      termMostRead,
                      'Most Read',
                    )}
                  />
                }
                entityQueryResult={
                  otherPosts as FragmentType<typeof PostTeasersFragment>
                }
                itemTitle={
                  <h4 className="h1-small h1-underline">
                    {termOtherPosts?.entityLabel}
                  </h4>
                }
                termIssue={renderBlockContentTranslation(termIssue)}
              />
            </>
          ) : null}
        </WrapperOtherPosts>

        <MostRead
          locale={language.locale ?? ''}
          style="mobile"
          termMostRead={renderBlockContentTranslation(
            termMostRead,
            'Most Read',
          )}
        />

        <div className={styles.Home__current_issue}>
          <h4 className="h1-small">
            {renderBlockContentTranslation(termCurrentIssue)}
          </h4>
          {currentIssue?.entities?.map((issue) => (
            <Issue
              issue={issue?.value as FragmentType<typeof IssueFragment>}
              key={issue?.uuid}
              label={renderBlockContentTranslation(termButtonAllIssues)}
            />
          ))}
        </div>
        <div className={styles.Home__teasers}>
          {teasers ? <Teasers language={language} teasers={teasers} /> : null}
        </div>
      </Layout>
    </div>
  );
};
/* eslint-enable @typescript-eslint/no-unsafe-assignment */

// this are the id's to get the filters right
// {field: "field_category.target_id", value: "..."}

const fixtures = {
  ids: {
    featured: 59,
    shortMessages: 61,
    otherPosts: 63,
    highlights: 75,
  },
};

export const getServerSideProps = withSession(async (context) => {
  const apolloClient = initializeApollo(null, context.req.jwt);

  const variables = withDefaultVariables(context, {
    featuredId: `${fixtures.ids.featured}`,
    shortMessagesId: `${fixtures.ids.shortMessages}`,
    shighlightsId: `${fixtures.ids.highlights}`,
    otherPostsId: `${fixtures.ids.otherPosts}`,
  });

  await apolloClient.query({
    query: HomeQueryDocument,
    variables,
  });

  return addApolloState(context, apolloClient, {
    props: {
      variables,
    },
  });
});

export default Home;
