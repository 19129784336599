// #region Global Imports
import React from 'react';
// #endregion Global Imports
// #region Local Imports
import { Adition } from '@components/Adition/Adition';
import { gql } from '@generated/gql';
import styles from './Ads.module.sass';
// #endregion Local Imports

export const AdsFragment = gql(/* GraphQL */ `
  fragment AdsFragment on EntityQueryResult {
    entities {
      ...AdFragment
    }
  }
`);

export const Ads: React.FunctionComponent = () => {
  return (
    <div className={styles.Ads__root}>
      <Adition
        active
        adId={[4473032, 4504602]} // de, fr
        carousel
        slot="RECT_300x250_1"
        style="rect"
      />
      <Adition
        adId={[4473033, 4504603]} // de, fr
        carousel
        slot="RECT_300x250_2"
        style="rect"
      />
      <Adition
        active
        adId={[4473029, 4504599]} // de, fr
        slot="SKY_160x600-300x600"
        style="sky"
      />
      <Adition
        active
        adId={[4473037, 4504607]} // de, fr
        carousel
        slot="RECT_300x250_3"
        style="rect"
      />
      <Adition
        adId={[4473038, 4504608]} // de, fr
        carousel
        slot="RECT_300x250_4"
        style="rect"
      />
    </div>
  );
};
