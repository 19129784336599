import * as React from 'react';
import styles from './styles.module.sass';

export const WrapperFeaturedShortmessagesHighlights: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.WrapperFeaturedShortmessagesHighlights}>
    {children}
  </div>
);

export const InnerFeaturedShortmessages: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.InnerFeaturedShortmessages}>{children}</div>
);

export const InnerHighlights: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => <div className={styles.InnerHighlights}>{children}</div>;
