import * as React from 'react';
import styles from './styles.module.sass';

export const WrapperFeaturedShortmessages: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.WrapperFeaturedShortmessages}>{children}</div>
);

export const InnerFeatured: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => <div className={styles.InnerFeatured}>{children}</div>;

export const InnerShortmessages: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.InnerShortmessages}>{children}</div>
);
