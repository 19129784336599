// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import { Evalanche } from '@components/Evalanche';
import styles from './Newsletter.module.sass';
import type { IProps } from './Teasers.d';
// #endregion Local Imports
export const Newsletter: React.FunctionComponent<IProps.Newsletter> = ({
  indexTeasers,
  indexContents,
  language,
}) => {
  const { locale } = language;
  if (indexTeasers === 1 && indexContents === 1) {
    return (
      <div className={styles.Newsletter__root}>
        <h4
          className={cx(
            styles.Newsletter__label,
            'Newsletter__label',
            'h1-small',
          )}
        >
          {locale === 'de' ? 'Aktuell Informiert' : 'Informé actuellement'}
        </h4>
        <h1 className={styles.Newsletter__title}>Newsletter</h1>
        <h3
          className={cx(
            styles.Newsletter__subtitle,
            'Newsletter__subtitle',
            'h3-small',
          )}
        >
          {locale === 'de'
            ? 'Jetzt unseren Newsletter abonnieren:'
            : 'Abonnez-vous à notre newsletter électronique:'}
        </h3>
        <Evalanche language={language} />
      </div>
    );
  }

  return <></>;
};
