// #region Global Imports
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
import type { ImageFragment } from '@components/Paragraphs/ParagraphMedia/Image';
import { Image } from '@components/Paragraphs/ParagraphMedia/Image';
import { Button } from '@components/Button';
import { gql } from '@generated/gql';
import type { IProps } from '@components/Issue/Issue';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import styles from './Issue.module.sass';
// #endregion Local Imports
export const IssueFragment = gql(/* GraphQL */ `
  fragment IssueFragment on TaxonomyTermIssue {
    id: uuid
    name

    image: fieldImage {
      entity {
        ...ImageFragment
      }
    }

    text: description {
      processed
    }

    label: entityLabel
  }
`);

export const Issue: React.FunctionComponent<IProps.Issue> = ({
  issue: _issue,
  label,
}) => {
  const issue = getFragmentData(IssueFragment, _issue);
  const edition: string[] | undefined = issue?.name?.split('/');

  return (
    <div className={styles.Issue__root}>
      <div className={styles.Issue__img}>
        <Image
          image={issue?.image?.entity as FragmentType<typeof ImageFragment>}
        />
      </div>
      <div className={styles.Issue__content}>
        <h1 className={styles.Issue__name}>{issue?.name}</h1>
        <div className={styles.Issue__info}>
          <div className={styles.Issue__txt}>
            <div
              dangerouslySetInnerHTML={{
                __html: String(issue?.text?.processed ?? ''),
              }}
            />
          </div>
          <div className={styles.Issue__btn}>
            <Button
              label={label}
              path={`/search?issue=${String(edition?.[0])}&year=${String(
                edition?.[1],
              )}&section=issue`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
