'use client';

// #region Global Imports
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import Link from 'next/link';
// #endregion Global Imports
// #region Local Imports
import { isArray } from 'lodash';
import styles from './MostRead.module.sass';
// #endregion Local Imports

const trimString = function (string: string, length: number): string {
  return string.length > length ? `${string.substring(0, length)}...` : string;
};

interface NodeCount {
  uri: string;
  title: string;
}

export const MostRead: React.FunctionComponent<{
  locale: string;
  termMostRead: string;
  style: string;
}> = ({ locale, termMostRead, style }) => {
  const [state, setState] = React.useState<{
    loading: boolean;
    data?: NodeCount[];
    error?: boolean;
  }>({
    loading: true,
    data: undefined,
    error: undefined,
  });

  React.useEffect(() => {
    void (async (): Promise<void> => {
      if (!state.data) {
        const res = await fetch(`/api/v1/nodeCount?locale=${locale}`);
        const { data } = (await res.json()) as { data: NodeCount[] };

        if (!res.ok || !isArray(data)) {
          setState({
            ...state,
            error: true,
          });

          return;
        }

        setState({
          ...state,
          loading: false,
          data,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <div
      className={cx(
        styles.MostRead__root,
        'PostTeasers__item',
        styles[`MostRead__root_${style}`],
      )}
    >
      <h4 className="h1-small h1-underline">{termMostRead}</h4>
      {state.loading ? (
        <Skeleton count={10} height="6%" width="100%" />
      ) : (
        <>
          {!isEmpty(state.data) && !state.error ? (
            <ol className={styles.MostRead__List}>
              {state.data?.map((link: NodeCount, index: number) => {
                return (
                  <li key={index}>
                    <Link
                      className={cx(styles.MostRead__link)}
                      href={locale.concat(link.uri)}
                    >
                      {trimString(link.title, 75)}
                    </Link>
                  </li>
                );
              })}
            </ol>
          ) : (
            'Something went wrong'
          )}
        </>
      )}
    </div>
  );
};
