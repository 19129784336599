// #region Global Imports
import * as React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Spinner } from '@components/Spinner';
// #endregion Global Imports
// #region Local Imports
import { gql } from '@generated/gql';
import type { FragmentType } from '@generated/fragment-masking';
import { getFragmentData } from '@generated/fragment-masking';
import type { DateFragment } from '@components/Date';
import { Date } from '@components/Date';
import type { ParagraphsPostFragment } from '@components/Paragraphs';
import type { CategoriesFragment, TagsFragment } from '@components/Taxonomies';
import { Categories, Tags } from '@components/Taxonomies';
import type { AuthorsFragment } from '@components/Authors/Authors';
import { AuthorList } from '@components/Authors/Authors';
import { Icon } from '@components/Icon';
import styles from './PostTeaser.module.sass';
import type { IProps } from './PostTeasers.d';
// #endregion Local Imports

const Paragraphs = dynamic(
  () => import('@components/Paragraphs').then((mod) => mod.Paragraphs),
  {
    loading: () => <Spinner />,
  },
);

export const PostTeaserFragment = gql(/* GraphQL */ `
  fragment PostTeaserFragment on NodePost {
    id: uuid
    title
    lead: fieldLead

    issue: fieldIssue {
      entity {
        id: uuid
        name
      }
    }

    entityUrl {
      path
    }

    dictated: fieldDictated

    # we grab this only to manipulate it.
    paragraphs: fieldContent {
      entity {
        uuid: entityUuid
        ...ParagraphFragment
        translation: entityTranslation(language: $language) {
          ... on ParagraphMedia {
            media: fieldMedia {
              entity {
                ... on MediaAudio {
                  uuid
                }
              }
            }
          }
        }
      }
    }

    authors: fieldAuthors {
      ...AuthorFragment
    }

    date: fieldDate {
      date
      value
    }

    ...CategoriesFragment
    ...TagsFragment
  }
`);

export const PostTeaser: React.FunctionComponent<IProps.PostTeaser> = ({
  teaser: _teaser,
  termIssue,
}) => {
  const router = useRouter();
  const teaser = getFragmentData(PostTeaserFragment, _teaser);
  const languageId = router.locale === 'de' ? 0 : 1;
  const audioTerm = ['Anhören', 'Écouter'];
  const hasAudio = teaser.dictated;

  return (
    <div className={styles.PostTeaser__root}>
      <Categories
        entity={teaser as FragmentType<typeof CategoriesFragment>}
        style="teaser"
      />
      <Tags
        entity={teaser as FragmentType<typeof TagsFragment>}
        style="teaser"
      />

      <Link
        className={styles.PostTeaser__content}
        href={String(teaser.entityUrl?.path)}
      >
        <>
          <p className={cx(styles.PostTeaser__issue, 'h2')}>
            {!isEmpty(teaser.issue?.entity?.name) ? (
              <>
                {termIssue} {teaser.issue?.entity?.name}
              </>
            ) : null}
          </p>

          <p className={styles.PostTeaser__byline}>
            {!isEmpty(teaser.date) ? (
              <Date entity={teaser as FragmentType<typeof DateFragment>} />
            ) : null}

            {!isEmpty(teaser.authors) ? (
              <>
                <span>,</span>
                <AuthorList
                  entity={teaser as FragmentType<typeof AuthorsFragment>}
                  style="postTeaser"
                />
                {hasAudio ? (
                  <span className={styles.PostTeaser__audioText}>
                    <span>&#160;&#160;&#183;&#160;</span>
                    <Icon icon="headphones" inline />
                    <span className={styles.PostTeaser__audioText_inner}>
                      &#160;{audioTerm[languageId]}
                    </span>
                  </span>
                ) : null}
              </>
            ) : null}
          </p>

          {!isEmpty(teaser.title) ? (
            <h3 className={cx(styles.PostTeaser__headline, 'h3-small')}>
              {teaser.title}
            </h3>
          ) : null}

          {!isEmpty(teaser.lead) ? (
            <div
              className={styles.PostTeaser__lead}
              dangerouslySetInnerHTML={{ __html: String(teaser.lead) }}
              suppressHydrationWarning
            />
          ) : null}

          {!isEmpty(teaser.paragraphs) ? (
            <Paragraphs
              entity={
                {
                  ...teaser,
                  paragraphs: teaser.paragraphs?.slice(0, 4),
                } as FragmentType<typeof ParagraphsPostFragment>
              }
            />
          ) : null}
        </>
      </Link>
    </div>
  );
};
