import * as React from 'react';
import styles from './styles.module.sass';

export const WrapperFeaturedShortmessagesHighlightsAds: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.WrapperFeaturedShortmessagesHighlightsAds}>
    {children}
  </div>
);

export const InnerFeaturedShortmessagesHighlights: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => (
  <div className={styles.InnerFeaturedShortmessagesHighlights}>{children}</div>
);

export const InnerAds: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => <div className={styles.InnerAds}>{children}</div>;
