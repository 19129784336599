'use client';

// #region Global Imports
import * as React from 'react';
import { withRouter } from 'next/router';
import cx from 'classnames';
// #endregion Global Imports
// #region Local Imports
import styles from './Adition.module.sass';
import type { IProps } from './Adition.d';
// #endregion Local Imports

export const Adition = withRouter(
  class Adition extends React.Component<
    IProps.AditionProps,
    IProps.AditionState
  > {
    constructor(props: IProps.AditionProps) {
      super(props);

      this.state = {
        adId: this.props.adId[
          this.props.router.locale === 'fr' ? 1 : 0
        ] as number,
        bannerCode: '',
        active: this.props.active,
      };
    }

    componentDidMount(): void {
      if (window.innerWidth > 764) {
        // Update 2023: I'll never get eslint to eat this.
        // wait till object is ready... not pretty but works
        const checkAditionExist = setInterval(() => {
          const { slot } = this.props;
          const { adId } = this.state;
          if (window.adition?.srq) {
            window.adition.srq.push((api: Record<string, any>) => {
              api.registerAdfarm('ad1.adfarm1.adition.com');
              api.configureRenderSlot(slot).setContentunitId(adId);
              api.load().completeRendering();
              api.renderSlot(slot);
              api.events.onFinishLoading((_: any, bannerDescription: any) => {
                if (
                  bannerDescription.getBannerCode() !== '' &&
                  (this.state.bannerCode === '') ===
                    bannerDescription.getBannerCode()
                ) {
                  this.setState({
                    ...this.state,
                    bannerCode: bannerDescription.getBannerCode(),
                  });
                }
              });
            });
            clearInterval(checkAditionExist);
          }
        }, 1000);
        if (this.props.carousel) {
          // NOTE: Before trying to debug this; componentDidMount will get called 2 times when ran local - see React.StrictMode
          setInterval(() => {
            this.setState({
              ...this.state,
              active: !this.state.active,
            });
          }, 8000);
        }
      }
    }

    render(): JSX.Element {
      const { slot, style } = this.props;
      return (
        <div
          className={cx(
            styles.Adition__Container,
            styles[`Adition__Container_${style ?? 'default'}`],
            `Adition__Container_${style ?? 'default'}`,
            {
              hiddenAd: !this.state.active,
            },
          )}
        >
          <div
            className={styles.Adition__item}
            dangerouslySetInnerHTML={{ __html: `${this.state.bannerCode}` }}
            id={slot}
            key={slot}
          />
        </div>
      );
    }
  },
);
